<template>
  <v-dialog
    v-model="dialogFacilitesPaiement"
    scrollable
    max-width="700"
    content-class="force-100-height"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-alert
        v-if="(!commande.acomptes && commande.first_acompte) || true"
        class="white--text d-block w-100 mb-0"
        color="info"
        href="javascript:void(0)"
        v-bind="attrs"
        v-on="on"
      >
        *{{ $t('decouvrez-facilites') }}<v-icon
          color="white"
          dense
        >
          mdi-chevron-right
        </v-icon>
      </v-alert>
    </template>
    <v-card class="card-facilites contenu-piscinelle">
      <v-card-title class="dialog-card-color pa-3 white--text flex-nowrap align-start">
        {{ $t('facilites') }}
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialogFacilitesPaiement = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="py-3 black--text"
        style="height:100%"
      >
        <h4 class="text-h5 my-7 mx-0">
          {{ "1. " + $t('le-financement') }}
        </h4>
        <v-alert
          v-if="!ppd"
          type="info"
        >
          {{ $t('install-non-ppd') }}
        </v-alert>
        <p class="text-h6 mt-3">
          {{ $t('combien') }}
        </p>
        <p class="text-subtitle-1">
          {{ $t('exemple-financement') + " " + (commande.offre_liee && ppd ? $n(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc), 'currency') : $n(commande.ce_mtt_client_ttc, 'currency')) +"." }}
        </p>
        <v-simple-table class="facilites-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('montant-financer') }}
                </th>
                <th>
                  {{ $t('nombre-mensualites') }}
                </th>
                <th>
                  {{ $t('montant-mensualites') }}
                  <span class="d-block">{{ $t('hors-assurance') }}</span>
                </th>
                <th>
                  {{ $t('montant-total') }}
                </th>
                <th>
                  {{ $t('taeg') }}
                </th>
              </tr>
            </thead>
            <tbody class="text-right">
              <tr
                v-for="(item, index) in items"
                :key="index"
              >
                <td>{{ (commande.offre_liee && ppd ? $n(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc), 'currency') : $n(commande.ce_mtt_client_ttc, 'currency')) }}</td>
                <td>{{ item.mens }}</td>
                <td>{{ commande.offre_liee && ppd ? $n(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),item.mens), 'currency') : $n(vpm(commande.ce_mtt_client_ttc,item.mens),'currency') }}</td>
                <td>{{ commande.offre_liee && ppd ? $n((montantTotalDu(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),item.mens),item.mens)),'currency') : $n((montantTotalDu(vpm(commande.ce_mtt_client_ttc,item.mens),item.mens)),'currency') }}</td>
                <td>{{ taeg() }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="text-h6 mt-3">
          {{ $t('comment') }}
        </p>
        <ul class="pl-4 mb-3 text-body-1">
          <li>{{ $t('profitez-report') }}</li>
          <li>{{ $t('mensualites-confort-obligatoire') }}</li>
          <li>{{ $t('apres-5-mensualites') + "*" }}</li>
        </ul>
        <v-simple-table class="facilites-table mb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('temps') }}
                </th>
                <th>
                  {{ $t('mensualites') }}
                </th>
              </tr>
            </thead>
            <tbody class="text-right">
              <tr>
                <td>{{ $t('M1') }}</td>
                <td>0</td>
              </tr>
              <tr>
                <td>{{ $t('M2') }}</td>
                <td>0</td>
              </tr>
              <tr>
                <td>{{ $t('M3') }}</td>
                <td>0</td>
              </tr>
              <tr>
                <td>{{ $t('M4') }}</td>
                <td>0</td>
              </tr>
              <tr>
                <td>{{ $t('M5') }}</td>
                <td>0</td>
              </tr>
              <tr>
                <td>{{ $t('M6') }}</td>
                <td>0</td>
              </tr>
              <tr>
                <td>{{ $t('M7') }}</td>
                <td>
                  {{ commande.offre_liee && ppd ? $n(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),180), 'currency') : $n(vpm(commande.ce_mtt_client_ttc,180),'currency') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('M8') }}</td>
                <td>{{ commande.offre_liee && ppd ? $n(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),180), 'currency') : $n(vpm(commande.ce_mtt_client_ttc,180),'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('M9') }}</td>
                <td>{{ commande.offre_liee && ppd ? $n(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),180), 'currency') : $n(vpm(commande.ce_mtt_client_ttc,180),'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('M10') }}</td>
                <td>{{ commande.offre_liee && ppd ? $n(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),180), 'currency') : $n(vpm(commande.ce_mtt_client_ttc,180),'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('M11') }}</td>
                <td>{{ commande.offre_liee && ppd ? $n(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),180), 'currency') : $n(vpm(commande.ce_mtt_client_ttc,180),'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('M12') }}</td>
                <td>{{ $t('gestion-libre') + "*" }}</td>
              </tr>
            </tbody>
            <caption class="text-body-1">
              {{ $t('mois-livraion') }}
            </caption>
          </template>
        </v-simple-table>
        <p class="text-body-1">
          {{ $t('12-mois-gestion-libre') + " : " }}
        </p>
        <ol class="text-body-1 pl-4 mb-3">
          <li>
            {{ $t('rembourser-integralite', {prix: (commande.offre_liee && ppd ? $n(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc), 'currency') : $n(commande.ce_mtt_client_ttc, 'currency')), interet: (commande.offre_liee && ppd ? $n(((montantTotalDu(vpm(coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc),180),180) - coutGlobal(commande.ce_mtt_client_ttc,commande.offre_liee.ce_mtt_client_ttc)) / 180 * 5).toFixed(2),'currency') : $n(((montantTotalDu(vpm(commande.ce_mtt_client_ttc,180),180) - commande.ce_mtt_client_ttc) / 180 * 5).toFixed(2),'currency')) }) }}
          </li>
          <li>{{ $t('faire-remboursement') }}</li>
          <li>{{ $t('financement') }}</li>
        </ol>
        <h4 class="text-h5 my-7 mx-0">
          {{ "2. " + $t('payer-comptant') }}
        </h4>
        <p class="text-h6 my-3">
          {{ commande.type_cmd == '6' ? $t('reglement-installation') : $t('reglement') }}
        </p>
        <p
          style="white-space: pre-line"
          class="text-body-1"
          :inner-html.prop="commande.paiement_modalites"
        />
        <div v-if="commande.offre_liee">
          <p class="text-h6 my-3">
            {{ $t('reglement-installation') }}
          </p>
          <p
            style="white-space: pre-line"
            class="text-body-1"
            :inner-html.prop="commande.offre_liee.paiement_modalites"
          />
        </div>
        <p class="text-h5 mt-10 mx-0">
          {{ $t('un-credit') }}
        </p>
        <p class="mb-0 font-italic text-body-2">
          {{ "*" + $t('voir-conditions') }}
        </p>
        <p class="font-italic text-body-2">
          {{ "**" + $t('remboursement') }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  name: 'Facilites',
  props: {
    commande: {
      type: Object,
      required: true
    },
    ppd: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      items: [
        {
          mens: 180
        },
        {
          mens: 120
        },
        {
          mens: 90
        }
      ],
      dialogFacilitesPaiement: false
    }
  },
  methods: {
    coutGlobal (offre, offreLiee) {
      return (parseInt(offre) + parseInt(offreLiee))
    },
    montantTotalDu (montantmens, mens) {
      return montantmens * mens
    }
  }
}
</script>
