<template>
  <v-card
    flat
    class="commande-card pa-1"
    @click="$emit('click')"
  >
    <v-avatar
      v-if="imgUrl != false"
      size="100"
      class="rounded float-right text-center"
    >
      <v-img
        :src="imgUrl"
        :lazy-src="imgUrl"
        cover
      />
    </v-avatar>
    <v-sheet>
      <div class="article-details float-none">
        <v-card-title class="desig pa-0 text-sm-subtitle-1 text-body-1 pr-3 flex-nowrap align-start">
          <p>
            {{ article.desig ? article.desig : article.nom }}<span v-if="article.qte > 1"> - {{ $t('quantite') }}: {{ article.qte }}
            </span>
          </p>
        </v-card-title>
        <v-card-subtitle
          v-if="article.contenu"
          class="desc text-caption pa-0 text-sm-body-2 pr-3"
        >
          <p class="mb-1">
            {{ article.contenu.params.lang[$i18n.locale].desc }}
          </p>
          <p
            v-if="article.remarque"
            class="mb-1 blue--text font-weight-bold"
          >
            {{ article.remarque }}
          </p>
          <p
            v-if="!hideSavoirPlus"
            class=" mb-0 text-body-2 muted--text"
          >
            {{ $t('savoir-plus') }}
          </p>
        </v-card-subtitle>
      </div>
      <div class="float-right clearfix text-right mt-2 d-flex flex-column">
        <p
          v-if="vpm(article.li_mtt_client_ttc, 180) > 0 && modeAffichagePrix == 1"
          class="mb-0 text-subtitle-1"
        >
          {{ $n(vpm(article.li_mtt_client_ttc, 180), 'currency') }} {{ $t('par-mois') }}*
        </p>
        <p
          v-if="article.li_mtt_public_ttc > 0 && article.remise_client_com > 0"
          class="font-weight-caption mb-0 text-body-2 muted--text text-decoration-line-through"
        >
          {{ $n(article.li_mtt_public_ttc, 'currency') }}
        </p>
        <v-chip
          v-if="article.remise_client_com > 0"
          color="green"
          class="font-weight-caption text-body-2 pa-1"
          label
          x-small
          outlined
        >
          -{{ article.type_remise_client_com == 'P' ? article.remise_client : $n(article.remise_client, 'currency') }}
        </v-chip>
        <p
          v-if="article.li_mtt_client_ttc >= 0"
          class="mb-0"
          :class="article.li_mtt_client_ttc == 0 ? 'text-subtitle-1 mb-0' : modeAffichagePrix == 2 ? 'text-subtitle-1' : 'muted--text text-body-2' "
        >
          {{ article.li_mtt_client_ttc != 0 ? $n(article.li_mtt_client_ttc, 'currency') + " " + $t('au-comptant') : (remarque_ligne_client(article.remarque) ? $t('client') : $t('inclus')) }}
        </p>
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: 'ArticleCard',
  props: {
    article: {
      type: Object,
      default: () => {}
    },
    modeAffichagePrix: {
      type: Number,
      default: 1
    },
    hideSavoirPlus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imgUrl () {
      if (this.article.contenu && this.article.contenu.params.miniature_url) {
        return this.article.contenu.params.miniature_url
      }
      return false
    }
  }
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
.commande-card{

  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  &:hover::before{
    opacity: 0.04;
  }
  .desig{
    word-break: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .desc{
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  &:focus:before{content:none}
}
</style>
