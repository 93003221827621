<template>
  <v-list
    v-if="contact"
    three-line
    rounded
    class="pa-0 pb-2"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'my-2'"
  >
    <p
      v-if="$vuetify.breakpoint.mdAndUp && showSav"
      class="text-h6 text-center mt-2 mb-0"
    >
      {{ isKit ? $t('besoin-assistance') : $t('probleme-piscinelle') }}
    </p>
    <p
      v-if="$vuetify.breakpoint.mdAndUp && showBtnDemandeDeRappel"
      class="text-h6 text-center mb-0"
    >
      {{ $t('nous-sommes-la') }}
    </p>
    <v-list-item class="flex-row flex-md-column mb-0 contact px-3">
      <v-list-item-avatar
        v-if="statut != 'commande' && statut != 'facture'"
        class="mx-auto"
        size="80"
        color="#F0F0F0"
      >
        <v-img
          :src="contact.photo_url"
          class="mr-2"
        />
      </v-list-item-avatar>
      <v-list-item-content
        class="text-left py-2"
        :class="$vuetify.breakpoint.mdAndDown ? 'ml-2' : ''"
      >
        <v-list-item-title v-if="!showSav">
          {{ contact.prenom }} {{ contact.nom_famille }}
        </v-list-item-title>
        <v-list-item-title v-else>
          {{ fonction }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="!showSav">
          {{ fonction }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-icon left>
            mdi-phone
          </v-icon>
          <a
            v-if="tel == 'telephone'"
            class="black--text"
            :href="`tel:${contact.telephone}`"
          >{{ contact.telephone }}</a>
          <a
            v-else
            class="black--text"
            :href="`tel:${contact.tel_portable}`"
          >{{ contact.tel_portable }}</a>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-icon left>
            mdi-email
          </v-icon> <a
            class="black--text"
            :href="`mailto:${contact.email}`"
          >{{ contact.email }}</a>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!-- Si c'est le commercial on appelle la carte de visite -->
    <CarteVisite
      v-if="Object.keys(client).length > 0 && contact.custom_field_array && contact.custom_field_array.anciennete"
      :contact="contact"
      :fonction="fonction"
      :statut="statut"
      :client="client"
      :show-sav="showSav"
      :is-petite-source="isPetiteSource"
      :show-btn-demande-de-rappel="showBtnDemandeDeRappel"
      :show-btn-signature="showBtnSignature"
      :prise-rdv-possible="priseRdvPossible"
      :tel="tel"
    />

    <Rdvs
      :client="client"
      :is-petite-source="isPetiteSource"
      :prise-rdv-possible="priseRdvPossible"
      :contact="contact"
      :show-btn-signature="showBtnSignature"
    />
    <DemandeDeRappel
      :show-btn-demande-de-rappel="showBtnDemandeDeRappel"
      :show-btn-signature="showBtnSignature"
      :client="client"
      :contact="contact"
    />
    <div class="text-center px-3">
      <v-btn
        v-if="showBtnSignature"
        elevation="2"
        :href="lienSignature"
        target="_blank"
        :class="client.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover'"
        class="w-100 my-3"
        color="primary"
      >
        <v-icon class="mr-1">
          mdi-pencil
        </v-icon>
        <span>{{ $t('signature-electronique') }}</span>
      </v-btn>
    </div>
  </v-list>
</template>

<style lang="scss">
#planning-rdv{
  & tr th, & tr td{
    border-right: 1px solid #ddd;
  }

  & .libelle{
    font-size: 15px;
  }

  #tooltip{
  width: 100%;
  height: 100%;
}

  & .indispo{
    background: repeating-linear-gradient(-45deg, #eee 0, #eee 3px, #f8f8f8 3px, #f8f8f8 6px);
    cursor: default;
  }

  & tr td:not(.indispo){
    cursor: pointer;

    &:hover{
      background: rgba(226, 0, 122, .1);
    }
  }

    & tbody {
    & tr:hover {
        background-color: transparent !important;
    }
  }
}
</style>
<script>
import CarteVisite from '@/components/contact/CarteVisite'
import Rdvs from '@/components/contact/Rdvs'
import DemandeDeRappel from '@/components/contact/DemandeDeRappel'

export default
{
  name: 'Contact',
  components: {
    CarteVisite,
    Rdvs,
    DemandeDeRappel
  },
  props:
  {
    contact:
    {
      type: Object,
      default: () => {}
    },
    lienSignature: {
      type: String,
      default: ''
    },
    client:
    {
      type: Object,
      default: () => {}
    },
    showBtnDemandeDeRappel:
    {
      type: Boolean,
      default: false
    },
    showBtnSignature:
    {
      type: Boolean,
      default: false
    },
    showSav: {
      type: Boolean,
      default: false
    },
    isKit: {
      type: Boolean,
      default: false
    },
    isPetiteSource: {
      type: Boolean,
      default: false
    },
    statut:
    {
      type: String,
      default: ''
    },
    fonction:
    {
      type: String,
      default: function () {
        return this.$t('service-client-piscinelle')
      }
    },
    tel: {
      type: String,
      default: 'tel_portable'
    },
    priseRdvPossible: {
      type: Boolean,
      default: false
    }
  }
}
</script>
