<template>
  <div
    v-if="!pageLoading && commande"
    class="home"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row>
            <Drawer
              v-if="!interne"
            />
            <v-col
              cols="12"
              :md="interne ? 12 : 9"
              class="ml-auto pa-0"
            >
              <Header
                :back-to="{name: 'Commandes'}"
                :back-text="$t('config-devis-commande')"
                :banniere="commande.ec_ps_banniere ? commande.ec_ps_banniere : ''"
              >
                <template v-slot:title>
                  <span class="mr-3 text-sm-h4 white--text font-weight-bold">{{ commande.statut == 'facture' || commande.statut == 'commande' ? $t('publication', {type: commande.statut === 'facture' || commande.statut === 'commande' ? $t('commande.statut.commande') : (commande.is_configInternaute || commande.is_petite_source_internaute ? $t('commande.statut.config') : $t('commande.statut.devis')),date: $d(new Date(commande.date_vente.replace(/-/g, "/")), 'short')}) : $t('publication', {type: commande.statut === 'facture' || commande.statut === 'commande' ? $t('commande.statut.commande') : (commande.is_configInternaute || commande.is_petite_source_internaute ? $t('commande.statut.config') : $t('commande.statut.devis')),date: $d(new Date(commande.date_modif.replace(/-/g, "/")), 'short')}) }}</span><br>
                  <v-chip
                    v-if="!commande.is_configInternaute && !commande.is_petite_source_internaute"
                    :href="'#'+commande.n"
                    small
                    class="mr-3 text-center pa-1 rounded-lg"
                    :color="commande.statut == 'devis' ? 'orange' : commande.statut == 'facture' ? 'dark-blue': 'green'"
                    text-color="white"
                    label
                  >
                    {{ $t('commande.statut.'+ commande.statut) }} {{ $t('commande.type.'+ commande.slug_type) }} #{{ commande.n }} {{ commande.statut == 'devis' ? '*' : '' }}
                  </v-chip>
                  <v-chip
                    v-if="commande.offre_liee"
                    :href="'#'+commande.offre_liee.n"
                    small
                    class="mr-3 text-center pa-1 rounded-lg"
                    :color="commande.offre_liee.statut == 'devis' ? 'orange' : commande.offre_liee.statut == 'facture' ? 'dark-blue': 'green'"
                    text-color="white"
                    label
                  >
                    {{ $t('commande.statut.'+ commande.offre_liee.statut) }} {{ $t('commande.type.'+ commande.offre_liee.slug_type) }} #{{ commande.offre_liee.n }} {{ commande.offre_liee.statut == 'devis' ? '*' : '' }}
                  </v-chip>
                  <v-chip
                    v-if="commande.is_configInternaute || commande.is_petite_source_internaute"
                    :href="'#'+commande.n"
                    small
                    label
                    text-color="white"
                    class="mr-3 text-center pa-1 rounded-lg"
                    color="blue"
                  >
                    <span>{{ (commande.is_petite_source_internaute ? $t('config-ps-non-contractuelle') : $t('config-non-contractuelle')) + " #" + commande.n + " *" }}</span>
                  </v-chip>
                  <v-chip
                    v-if="commande.date_validite && commande.statut== 'devis' && !commande.is_configInternaute && !commande.is_petite_source_internaute && !commande.offre_liee"
                    small
                    :color="commande.date_validite >= today() ? 'blue' : 'orange'"
                    text-color="white"
                    label
                    class="text-center pa-1 rounded-lg"
                  >
                    <span v-if="commande.date_validite >= today()"><v-icon
                      class="vertical-baseline mr-1"
                      small
                    >mdi-calendar</v-icon>{{ $t('commande-date-expiration', {date: $d(new Date(commande.date_validite.replace(/-/g, "/")), 'short')}) }}</span>
                    <span v-else><v-icon
                      class="vertical-baseline mr-1"
                      small
                    >mdi-calendar</v-icon>{{ $t('commande-expire', {date: $d(new Date(commande.date_validite.replace(/-/g, "/")), 'short')}) }}</span>
                  </v-chip>
                  <template v-else />
                </template>
                <template v-slot:subtitle>
                  <v-chip
                    v-if="commande.date_validite && commande.statut== 'devis' && !commande.is_configInternaute && !commande.is_petite_source_internaute && commande.offre_liee"
                    small
                    :color="commande.date_validite >= today() ? 'blue' : 'orange'"
                    text-color="white"
                    label
                    class="text-center pa-1 rounded-lg mr-3"
                  >
                    <span v-if="commande.date_validite >= today()"><v-icon
                      class="vertical-baseline mr-1"
                      small
                    >mdi-calendar</v-icon>{{ $t('commande-date-expiration', {date: $d(new Date(commande.date_validite.replace(/-/g, "/")), 'short')}) }}</span>
                    <span v-else><v-icon
                      class="vertical-baseline mr-1"
                      small
                    >mdi-calendar</v-icon>{{ $t('commande-expire', {date: $d(new Date(commande.date_validite.replace(/-/g, "/")), 'short')}) }}</span>
                  </v-chip>
                  <template v-else />
                  <v-chip
                    v-if="commande.signature_en_cours.lien && commande.date_validite && commande.date_validite >= today()"
                    small
                    text-color="white"
                    label
                    class="text-center pa-1 rounded-lg"
                    color="blue"
                  >
                    <span><v-icon
                      class="mr-1 vertical-baseline"
                      small
                    >mdi-pencil</v-icon>{{ $t('pret-a-signer') }}</span>
                  </v-chip>
                  <template v-else />
                  <p class="text-caption white--text mb-0">
                    {{ commande.offre_titre }}
                  </p>
                  <span
                    v-if="commande.statut == 'commande' && commande.offre_liee"
                    class="d-block text-caption grey--text text--lighten-1 mb-4"
                  >
                    {{ $t('date.vente', { date: $d(new Date(commande.date_vente.replace(/-/g, "/")), 'short')}) }}
                  </span>
                  <template v-else />
                  <span
                    v-if="commande.is_petite_source && commande.date_livraison !== '0000-00-00'"
                    class="d-block text-caption grey--text text--lighten-1"
                  >
                    {{ $t('date.livraison', {date: $d(new Date(commande.date_livraison.replace(/-/g, "/")), 'short')}) }}
                  </span>
                  <span
                    v-if="commande.statut == 'devis' || commande.is_configInternaute || commande.is_petite_source_internaute"
                    class="d-block text-caption grey--text text--lighten-1 mb-4"
                  >
                    {{ $t('date.modification', {date: $d(new Date(commande.date_modif.replace(/-/g, "/")), 'long')}) }}
                  </span>
                  <div
                    v-if="commande.statut == 'devis'"
                  >
                    <div
                      v-if="commande.is_configInternaute || commande.is_petite_source_internaute"
                    >
                      <a
                        class="white--text text-decoration-none"
                        :href="modifierConfigUrl() + '&email=' + user.email"
                      ><v-icon
                        color="white"
                        class="vertical-top mr-1"
                      >mdi-cog</v-icon>{{ $t('modifier-config') }}</a>
                      <template>
                        <v-dialog
                          v-model="dialogSupprimerConfig"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              class="ml-3 white--text text-decoration-none"
                              v-bind="attrs"
                              v-on="on"
                            ><v-icon
                              color="white"
                              class="vertical-top mr-1"
                            >mdi-delete</v-icon>{{ $t('supprimer-config') }}</a>
                          </template>
                          <v-card class="py-4">
                            <v-card-text>{{ $t('confirm-suppression') }}</v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn
                                :loading="loading"
                                class="white--text"
                                :class="user.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover'"
                                @click="supprimerConfig()"
                              >
                                {{ $t('supprimer') }}
                              </v-btn>
                              <v-btn
                                @click="dialogSupprimerConfig = false"
                              >
                                {{ $t('annuler') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                    </div>
                  </div>
                </template>
              </Header>

              <v-alert
                v-if="commande.statut != 'facture' && commande.signature_en_cours.lien && commande.date_validite && commande.date_validite >= today()"
                class="rounded-0"
                type="info"
              >
                {{ $t('alert-signature') }}
                <a
                  target="_blank"
                  class="white--text"
                  :href="commande.signature_en_cours.lien.yousign_url"
                >
                  <v-icon
                    class="vertical-baseline text-decoration-underline"
                    small
                  >mdi-pencil</v-icon>{{ $t('signature-electronique') }}</a>
              </v-alert>
              <v-alert
                v-if="!commande.is_acompte_ok && (commande.statut == 'commande' || commande.statut == 'facture' || (commande.statut=='devis' && !commande.is_configInternaute && !commande.is_petite_source_internaute && commande.documents_joints.length > 0))"
                class="rounded-0"
                type="info"
              >
                {{ $t('alert-acompte') }}
                <a
                  class="white--text"
                  href="#acompte"
                >
                  {{ $t('comment-payer-acompte') }}</a>
              </v-alert>
              <v-alert
                v-if="success"
                class="rounded-0"
                type="success"
              >
                <span v-if="commande.vendeur_infos && commande.vendeur_infos.creneaux">{{ $t('rdv-pris') }}</span>
                <span v-else>{{ $t('demande-rdv') }}</span>
              </v-alert>
              <v-alert
                v-if="demande_rappel"
                class="rounded-0"
                type="success"
              >
                {{ $t('demande-passer-commande-faite') }}
              </v-alert>
              <v-alert
                v-if="avisLaisse"
                class="rounded-0"
                type="success"
              >
                {{ $t('avis-laisse') }}
              </v-alert>
              <v-alert
                v-if="envoi_mail_proche"
                class="rounded-0"
                type="success"
              >
                {{ $t('conjoint-acces') }}
              </v-alert>
              <v-alert
                v-if="suppression"
                class="rounded-0"
                type="success"
              >
                {{ $t('config-supprime') }}
              </v-alert>
              <v-alert
                v-if="commande.is_configInternaute || commande.is_petite_source_internaute"
                class="rounded-0"
                type="info"
              >
                {{ $t('votre-configuration-chiffree') }}
              </v-alert>
              <v-row class="d-none sticky-top-to-scroll mx-0 scrollspy-row">
                <v-col class="pa-0">
                  <v-select
                    v-model="selectedScrollSpyItem"
                    attach
                    dark
                    :items="scrollSpyItems"
                    :label="$t('sections')"
                    item-text="titre"
                    item-value="id"
                    solo
                    class="scrollspy-select"
                    return-object
                    @change="goToSection()"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-1 ml-0">
                <v-col
                  class="pb-350"
                  :class="interne? 'mx-auto' : ''"
                  cols="12"
                  :md="interne ? 6 : 8"
                >
                  <v-row
                    v-if="commande.custom_field_array.banniere"
                    class="my-15"
                  >
                    <v-col cols="12">
                      <v-img
                        max-height="100vh"
                        :lazy-src="commande.custom_field_array.banniere"
                        :src="commande.custom_field_array.banniere"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="commande.statut == 'facture'"
                    class="my-15"
                  >
                    <v-col
                      cols="12"
                    >
                      <div>
                        <v-card
                          flat
                          tile
                          class="info pa-3"
                        >
                          <p
                            v-if="commande.is_kit"
                            class="text-h6 white--text"
                          >
                            {{ $t('besoin-assistance') }}
                          </p>
                          <p
                            v-else
                            class="text-h6 white--text"
                          >
                            {{ $t('probleme-piscinelle') }}
                          </p>
                          <p class="white--text mb-0">
                            <v-icon
                              color="white"
                              left
                            >
                              mdi-phone
                            </v-icon>
                            <a
                              class="white--text"
                              :href="`tel:${commande.sav_infos.telephone}`"
                            >{{ commande.sav_infos.telephone }}</a>
                          </p>
                          <p class="white--text mb-0">
                            <v-icon
                              color="white"
                              left
                            >
                              mdi-email
                            </v-icon> <a
                              class="white--text"
                              :href="`mailto:${commande.sav_infos.email}`"
                            >{{ commande.sav_infos.email }}</a>
                          </p>
                          <p class="white--text">
                            {{ commande.sav_infos.jours }}<br>
                            {{ commande.sav_infos.horaires }}
                          </p>
                          <p
                            v-if="commande.is_kit"
                            class="white--text mb-0"
                          >
                            {{ $t('sav_ressources_utiles_pre_link') }}
                            <a
                              class="white--text"
                              href="#ressources_utiles"
                            >ressources utiles</a>
                            {{ $t('sav_ressources_utiles_post_link') }}
                          </p>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.statut == 'facture'"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.type_cmd == 1"
                    class="my-15"
                  >
                    <v-col
                      cols="12"
                      class="first-col"
                    >
                      <div
                        v-if="commande.statut != 'devis'"
                        id="statut_commande"
                        class="scrollme"
                      >
                        <p class="text-h4">
                          {{ $t('statut_commande') }}
                        </p>

                        <v-card
                          flat
                        >
                          <div :inner-html.prop="commande.statut_administratif" />
                        </v-card>
                      </div>
                      <div
                        v-else
                        id="etapes_projet"
                        class="scrollme"
                      >
                        <p class="text-h4">
                          {{ $t('etapes_projet') }}
                        </p>
                        <p class="muted--text">
                          {{ $t('etapes_projet_desc') }}
                        </p>
                        <v-card flat>
                          <v-stepper
                            flat
                            vertical
                            :value="commande.documents_joints.length > 0 ? '4' : (commande.signature_en_cours != false ? '3' : (!commande.is_configInternaute && !commande.is_petite_source_internaute ? '2' : '1'))"
                            class="pb-0"
                          >
                            <v-stepper-step
                              class="py-3"
                              :complete="commande.signature_en_cours != false || (!commande.is_configInternaute && !commande.is_petite_source_internaute)"
                              step="1"
                            >
                              <p class="mb-0">
                                <v-icon class="mr-1">
                                  mdi-cog
                                </v-icon><span class="vertical-center">{{ $t('piscinelle-ideale') }}</span>
                              </p>
                            </v-stepper-step>
                            <v-stepper-content step="1" />

                            <v-stepper-step
                              class="py-3"
                              :complete="commande.signature_en_cours != false"
                              color="primary"
                              step="2"
                            >
                              <p class="mb-0">
                                <v-icon class="mr-1">
                                  mdi-check-bold
                                </v-icon>
                                <span class="vertical-center">{{ $t('valider-projet') }}</span>
                              </p>
                            </v-stepper-step>
                            <v-stepper-content step="2" />
                            <v-stepper-step
                              class="py-3"
                              :complete="commande.documents_joints.length > 0"
                              color="primary"
                              step="3"
                            >
                              <p class="mb-0">
                                <v-icon class="mr-1">
                                  mdi-pencil
                                </v-icon>
                                <span class="vertical-center">{{ $t('signer-payer') }}</span>
                              </p>
                            </v-stepper-step>
                            <v-stepper-content step="3" />
                            <v-stepper-step
                              class="py-3"
                              step="4"
                            >
                              <p class="mb-0">
                                <v-icon class="mr-1">
                                  mdi-text-box-search
                                </v-icon>
                                <span class="vertical-center">{{ $t('suivi-commande') }}</span>
                              </p>
                            </v-stepper-step>
                          </v-stepper>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.type_cmd == 1"
                    class="big-divider"
                  />
                  <ChoisirPiscinelle
                    v-if="commande.statut == 'devis' && !commande.is_petite_source"
                    :commande="commande"
                  />
                  <v-row class="mb-15">
                    <v-col
                      cols="12"
                    >
                      <div
                        :id="commande.n"
                        class="pt-15"
                      >
                        <CommandeCard
                          :class="commande.type_cmd == 6 || commande.type_cmd == 8 || (commande.type_cmd == 7 && commande.statut != 'devis') ? 'first-col' : ''"
                          :ancre="true"
                          :disable-interaction="commande.statut == 'facture' ? true : false"
                          :commande="commande"
                          :modifier-config-url="modifierConfigUrl()"
                          :mode-affichage-prix="getModeAffichagePrix(commande,user)"
                        />
                      </div>
                      <v-divider
                        v-if="commande.offre_liee"
                        class="mt-15 big-divider"
                      />
                      <div
                        v-if="commande.offre_liee"
                        :id="commande.offre_liee.n"
                        class="pt-15"
                      >
                        <CommandeCard
                          v-if="commande.offre_liee"
                          :disable-interaction="commande.offre_liee.statut == 'facture' ? true : false"
                          :install="true"
                          :commande="commande.offre_liee"
                          :mode-affichage-prix="getModeAffichagePrix(commande.offre_liee,user)"
                        />
                      </div>
                      <v-divider
                        v-if="commande.offre_liee"
                        class="my-15 big-divider"
                      />
                      <div
                        v-if="commande.offre_liee"
                        id="cout_global"
                        class="scrollme"
                      >
                        <div class="d-flex">
                          <p class="text-h4">
                            {{ $t('cout_global') }}
                          </p>
                          <v-btn
                            small
                            icon
                            fab
                            class="ml-auto"
                          >
                            <v-icon
                              v-if="show_global"
                              @click="show_global = !show_global"
                            >
                              mdi-arrow-down-drop-circle
                            </v-icon>
                            <v-icon
                              v-if="!show_global"
                              @click="show_global = !show_global"
                            >
                              mdi-arrow-up-drop-circle
                            </v-icon>
                          </v-btn>
                        </div>
                        <p class="muted--text">
                          {{ $t('cout_global_desc') }}
                        </p>
                        <div
                          v-if="show_global"
                          class="elevation rounded-sm"
                        >
                          <v-card
                            class="contenu-piscinelle font-weight-medium pa-2"
                            flat
                          >
                            <div class="d-flex justify-center align-center justify-space-between">
                              <span class="text-h6 font-weight-medium">{{ commande.type_cmd == '6' ? $t('total_ttc_install') : $t('total_ttc_materiel') }}</span>
                              <div class="ttc d-flex flex-column align-end text-right">
                                <span
                                  v-if="commande.statut == 'devis' && getModeAffichagePrix(commande,user) == 1"
                                  class="text-h6"
                                >{{ $n(vpm(commande.ce_mtt_client_ttc,180), 'currency') }} {{ $t('par-mois') }}*</span>
                                <span
                                  class="ttc-comptant"
                                  :class="getModeAffichagePrix(commande,user) == 1 ? 'muted--text text-body-1' : 'text-h6'"
                                >{{ $n(commande.ce_mtt_client_ttc, 'currency') }} {{ $t('au-comptant') }}</span>
                                <span class="text-body-1 muted--text">{{ (commande.type_cmd == 7 || commande.type_cmd == 1) && !commande.offre_liee ? " " + $t('contenu.hors-installation') : "" }}</span>
                              </div>
                            </div>
                            <p
                              v-if="commande.type_cmd == '6'"
                              class="societe-install font-weight-regular mb-0 mt-1 muted--text"
                            >
                              {{ $t('societe_install') }} <span class="font-weight-bold">{{ commande.societe_nom }}</span>
                              <span v-if="commande.societe_interne"> &middot; {{ $t('societe_filiale') }}</span>
                            </p>
                            <p
                              v-else-if="(commande.type_cmd == '1' || commande.type_cmd == '7')"
                              class="font-weight-regular mb-0 mt-1 muted--text"
                            >
                              {{ $t('materiel_facture') }} <span class="font-weight-bold">{{ commande.societe_nom }}</span>
                              <span v-if="commande.societe_interne"> &middot; {{ $t('societe_filiale') }}</span>
                            </p>
                          </v-card>
                          <v-divider />
                          <v-card
                            v-if="commande.offre_liee"
                            class="contenu-piscinelle font-weight-medium pa-2"
                            flat
                          >
                            <div class="d-flex justify-center align-center justify-space-between">
                              <span class="text-h6 font-weight-medium">{{ commande.offre_liee.type_cmd == '6' ? $t('total_ttc_install') : $t('total_ttc_materiel') }}
                              </span>
                              <div class="ttc d-flex flex-column align-end text-right">
                                <span
                                  v-if="commande.offre_liee.statut == 'devis' && getModeAffichagePrix(commande.offre_liee,user) == 1"
                                  class="text-h6"
                                >{{ $n(vpm(commande.offre_liee.ce_mtt_client_ttc,180), 'currency') }} {{ $t('par-mois') }}*</span>
                                <span
                                  class="ttc-comptant"
                                  :class="getModeAffichagePrix(commande.offre_liee,user) == 1 ? 'muted--text text-body-1' : 'text-h6'"
                                >{{ $n(commande.offre_liee.ce_mtt_client_ttc, 'currency') }} {{ $t('au-comptant') }}</span>
                                <span class="text-body-1 muted--text">{{ (commande.type_cmd == 7 || commande.type_cmd == 1) && !commande.offre_liee ? " " + $t('contenu.hors-installation') : "" }}</span>
                              </div>
                            </div>
                            <p
                              v-if="commande.offre_liee.type_cmd == '6'"
                              class="societe-install font-weight-regular mb-0 mt-1 muted--text"
                            >
                              {{ $t('societe_install') }} <span class="font-weight-bold">{{ commande.offre_liee.societe_nom }}</span>
                              <span v-if="commande.offre_liee.societe_interne">- {{ $t('societe_filiale') }}</span>
                            </p>
                            <p
                              v-else-if="commande.offre_liee.type_cmd == '1' || commande.offre_liee.type_cmd == '7'"
                              class="font-weight-regular mb-0 mt-1 muted--text"
                            >
                              {{ $t('materiel_facture') }} <span class="font-weight-bold">{{ commande.societe_nom }}</span>
                              <span v-if="commande.societe_interne"> &middot; {{ $t('societe_filiale') }}</span>
                            </p>
                          </v-card>
                          <v-divider
                            v-if="commande.offre_liee"
                          />
                          <v-card
                            v-if="commande.offre_liee"
                            class="contenu-piscinelle font-weight-medium text-body-1 d-flex justify-center align-center justify-space-between pa-2"
                            flat
                          >
                            <span class="font-weight-medium text-h6">{{ $t('cout_global') }}</span>
                            <div class="ttc d-flex flex-column align-end text-right">
                              <span
                                v-if="commande.statut == 'devis' && (getModeAffichagePrix(commande,user) == 1 && getModeAffichagePrix(commande.offre_liee,user) == 1)"
                                class="text-h6"
                              >{{ $n(vpm(coutGlobal(commande.ce_mtt_client_ttc, commande.offre_liee.ce_mtt_client_ttc),180), 'currency') }} {{ $t('par-mois') }}*</span>
                              <span
                                class="ttc-comptant"
                                :class="(getModeAffichagePrix(commande,user) == 1 && getModeAffichagePrix(commande.offre_liee,user) == 1) ? 'muted--text text-body-1' : 'text-h6'"
                              >{{ $n(coutGlobal(commande.ce_mtt_client_ttc, commande.offre_liee.ce_mtt_client_ttc), 'currency') }} {{ $t('au-comptant') }}</span>
                            </div>
                          </v-card>
                          <div class="pa-2">
                            <Facilites
                              v-if="commande.offre_liee"
                              :ppd="commande.is_install_ppd"
                              :commande="commande"
                            />
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.infos_bassin && !commande.is_configInternaute"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.infos_bassin && !commande.is_configInternaute"
                    class="my-15"
                  >
                    <v-col
                      id="infos_bassin"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('infos_bassin') }}
                      </p>
                      <p class="muted--text">
                        {{ $t('infos_bassin_desc', {type: commande.is_petite_source ? $t('commande.type.petite-source') : $t('piscinelle')}) }}
                      </p>
                      <InfosListe
                        :items="commande.infos_bassin"
                      />
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.is_petite_source && contenu_prerequis_technique"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.is_petite_source && contenu_prerequis_technique"
                    class="my-15"
                  >
                    <v-col
                      id="prerequis_techniques"
                      cols="12"
                      class="scrollme"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <p
                        v-html="contenu_prerequis_technique"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.infos_installation && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.infos_installation && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="my-15"
                  >
                    <v-col
                      id="infos_installation"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('infos_installation') }}
                      </p>
                      <InfosListe
                        :items="commande.infos_installation"
                      />
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.infos_zone_a_couvrir && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.infos_zone_a_couvrir && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="my-15"
                  >
                    <v-col
                      id="infos_zone_a_couvrir"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('infos_zone_a_couvrir') }}
                      </p>
                      <InfosListe
                        :items="commande.infos_zone_a_couvrir"
                      />
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.infos_rd && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.infos_rd && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="my-8"
                  >
                    <v-col
                      id="infos_rolling_deck"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('infos_rolling_deck') }}
                      </p>
                      <p class="muted--text">
                        {{ $t('infos_rd_desc') }}
                      </p>
                      <InfosListe
                        :items="commande.infos_rd"
                      />
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="!commande.is_configInternaute"
                    class="big-divider"
                  />
                  <v-row
                    v-if="!commande.is_configInternaute"
                    class="my-8"
                  >
                    <v-col
                      id="adresses"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('mes-adresses') }}
                      </p>
                      <p class="muted--text">
                        {{ $t('adresses_desc') }}
                      </p>
                      <div>
                        <v-list
                          rounded
                          class="pa-0"
                        >
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="mb-3">
                                <v-icon
                                  color="indigo"
                                  left
                                >
                                  mdi-truck-fast
                                </v-icon> {{ $t('adresse.livraison') }}
                              </v-list-item-title>
                              <v-list-item-title>{{ commande.civ_liv }} {{ commande.nom_liv }} {{ commande.prenom_liv }}</v-list-item-title>
                              <v-list-item-subtitle>
                                {{ commande.adr1_liv }}, {{ commande.adr2_liv }} {{ commande.cp_liv }} {{ commande.ville_liv }} {{ commande.pays_liv }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ commande.tel1_liv }} {{ commande.tel2_liv }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="!commande.is_configInternaute && !commande.is_petite_source_internaute && (commande.type_cmd == 1 || commande.type_cmd == 7)">
                                <v-chip
                                  class="d-block w100 text-center"
                                  label
                                  color="grey"
                                  text-color="white"
                                  small
                                >
                                  {{ $t('date-livraison' , {date : $d(new Date(commande.date_expedition.replace(/-/g, "/")), 'short')}) }}
                                </v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="my-2" />
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="mb-3">
                                <v-icon
                                  color="indigo"
                                  left
                                >
                                  mdi-receipt
                                </v-icon>
                                {{ $t('adresse.facturation') }}
                              </v-list-item-title>
                              <v-list-item-title>{{ commande.civ_fact }} {{ commande.nom_fact }} {{ commande.prenom_fact }}</v-list-item-title>
                              <v-list-item-subtitle>
                                {{ commande.adr1_fact }}, {{ commande.adr2_fact }} {{ commande.cp_fact }} {{ commande.ville_fact }} {{ commande.pays_fact }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ commande.tel1_fact }}  {{ commande.tel2_fact }} - {{ commande.mail_fact }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="schemas.length && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="big-divider"
                  />
                  <v-row
                    v-if="schemas.length && !commande.is_configInternaute && !commande.is_petite_source_internaute"
                    class="my-15"
                  >
                    <v-col
                      id="schemas_implantation"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('schemas_implantation') }}
                      </p>
                      <p class="muted--text">
                        {{ $t('schemas_implantation_desc') }}
                      </p>
                      <v-card
                        flat
                      >
                        <v-card-text
                          v-fancybox:schemas
                          class="px-0 d-flex flex-column flex-sm-row align-center flex-nowrap flex-sm-wrap"
                        >
                          <v-img
                            v-for="(schema, index) in schemas"
                            :key="index"
                            :src="commande.type_cmd != 7 ? schema : schema.image"
                            class="mb-3 cursor-pointer schemas"
                            width="auto"
                            max-width="50%"
                            :data-src="commande.type != 7 ? schema : schema.image"
                          />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.is_eligible_infos_chantier_ec"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.is_eligible_infos_chantier_ec"
                    class="my-15"
                  >
                    <v-col
                      id="infos_chantier"
                      cols="12"
                      class="scrollme infos-chantier"
                    >
                      <p class="text-h4">
                        {{ $t('infos_chantier') }}
                      </p>
                      <p class="muted--text">
                        {{ $t('infos_chantier_desc') }}
                      </p>
                      <v-card
                        flat
                      >
                        <v-card-text class="px-0">
                          <div :inner-html.prop="commande.recap_infos_chantier" />
                        </v-card-text>
                        <v-card-actions class="px-0">
                          <v-btn
                            small
                            @click="infos_chantier = true"
                          >
                            {{ $t('afficher') }}
                          </v-btn>
                        </v-card-actions>
                        <v-dialog
                          v-model="infos_chantier"
                          :persistent="$vuetify.breakpoint.smAndDown"
                          scrollable
                          max-width="750px"
                          content-class="force-100-height infos-chantier"
                          transition="dialog-bottom-transition"
                          :fullscreen="$vuetify.breakpoint.smAndDown"
                        >
                          <v-card>
                            <v-card-title
                              class="dialog-card-color pa-3 white--text flex-nowrap align-start"
                            >
                              {{ $t('infos_chantier') }}
                              <v-spacer />
                              <v-btn
                                icon
                                dark
                                @click="infos_chantier = false"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text
                              class="pa-5"
                              style="height: 100%;"
                            >
                              <div :inner-html.prop="commande.infos_chantier" />
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="commande.type_cmd == 1 && commande.statut != 'devis'"
                    class="big-divider"
                  />
                  <v-row
                    v-if="commande.type_cmd == 1 && commande.statut != 'devis'"
                    class="my-8"
                  >
                    <v-col
                      id="ressources_utiles"
                      cols="12"
                      class="scrollme"
                    >
                      <p class="text-h4">
                        {{ $t('ressources_utiles') }}
                      </p>
                      <p class="muted--text">
                        {{ $t('ressources_utiles_desc') }}
                      </p>
                      <v-alert type="info">
                        {{ $t('notes_ressources_utiles') }}
                      </v-alert>
                      <div class="elevation">
                        <v-list class="pa-0">
                          <template
                            v-for="(ressource, index) in ressourcesUtilesItems"
                          >
                            <v-divider
                              v-if="index > 0 && !ressource.hide"
                              :key="`divider-${index}`"
                            />
                            <v-list-item
                              v-if="!ressource.hide"
                              :key="`list-${index}`"
                              :to="ressource.to"
                              :href="ressource.href"
                              :target="ressource.href ? '_blank' : undefined"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="ressource.icon" />
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t(ressource.keyTrad) }} <sup
                                    v-if="ressource.important"
                                    class="colorPrimary font-weight-bold"
                                  >{{ $t('nouveau') }}</sup>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    v-if="commande.is_configInternaute || commande.is_petite_source_internaute || (commande.statut == 'devis' && commande.type_cmd == 1)"
                    class="mb-4 line-height-1"
                  >
                    <small class="muted--text">{{ "* " + $t('nota-bene-materiel') }}</small>
                  </div>
                  <div
                    v-if="(commande.statut == 'devis' && commande.type_cmd == 6) || (commande.offre_liee && commande.offre_liee.statut == 'devis' && commande.offre_liee.type_cmd == 6)"
                    class="line-height-1"
                  >
                    <small class="muted--text">{{ "* " + $t('nota-bene-installation') }} <br> {{ $t('nota-bene-installation-2-paragraphe') }}</small>
                  </div>
                  <div v-if="commande.statut == 'devis' && !commande.is_configInternaute && !commande.is_petite_source_internaute">
                    <a
                      :href="urlGenererPdf()"
                      target="_blank"
                      class="muted--text text-decoration-none mt-2 d-block"
                    >
                      <v-icon
                        color="muted"
                        class="vertical-top mr-1"
                      >mdi-file-pdf-box</v-icon><small>{{ $t('voir-pdf') }}</small>
                    </a>
                  </div>
                  <div v-if="commande.statut == 'devis' && !commande.is_configInternaute && !commande.is_petite_source_internaute && commande.cgv_url">
                    <a
                      :href="commande.cgv_url"
                      target="_blank"
                      class="muted--text text-decoration-none mt-2 d-block"
                    >
                      <v-icon
                        color="muted"
                        class="vertical-top mr-1"
                      >mdi-file-sign</v-icon><small>{{ $t('voir-cgv') }}</small>
                    </a>
                  </div>
                  <div
                    v-if="commande.statut == 'devis' && (commande.is_configInternaute || commande.is_petite_source_internaute ) && commande.type_cmd == 1"
                    class="share-links mt-3"
                  >
                    <p class="mb-0">
                      {{ $t('partage-configuration') }} :
                    </p>
                    <template
                      v-for="(reseau,index) in reseauxSociaux"
                    >
                      <a
                        :key="index"
                        :href="reseau.share_url"
                        target="_blank"
                        class="mr-2 text-decoration-none"
                        :data-action="reseau.action ? reseau.action : ''"
                      >
                        <v-icon
                          :color="reseau.color"
                        >{{ reseau.icon }}</v-icon>
                      </a>
                    </template>
                  </div>
                </v-col>
                <v-col
                  v-if="!interne"
                  cols="4"
                >
                  <template v-if="$vuetify.breakpoint.mdAndUp">
                    <div
                      class="elevation contacts mt-13 sticky-top-to-scroll top-100"
                    >
                      <Contact
                        v-if="commande.statut == 'facture'"
                        :contact="commande.sav_infos"
                        :show-sav="true"
                        :is-petite-source="commande.is_petite_source"
                        :is-kit="commande.is_kit"
                        :client="{}"
                        :statut="commande.statut"
                        tel="telephone"
                        :prise-rdv-possible="false"
                      />
                      <v-divider v-if="commande.statut == 'facture'" />
                      <Contact
                        :contact="commande.vendeur_infos"
                        :client="user"
                        :lien-signature="commande.signature_en_cours.lien ? commande.signature_en_cours.lien.yousign_url : ''"
                        :fonction="$t('votre-conseiller-piscinelle')"
                        tel="tel_portable"
                        :show-btn-demande-de-rappel="commande.statut != 'facture' && !commande.vendeur_infos.custom_field_array.desactiver_demande_de_rappel ? true : false"
                        :statut="commande.statut"
                        :show-btn-signature="commande.statut != 'facture' && commande.signature_en_cours && commande.date_validite && commande.date_validite >= today() ? true : false"
                        :config-internaute="commande.is_configInternaute || commande.is_petite_source_internaute"
                        :is-petite-source="commande.is_petite_source"
                        :prise-rdv-possible="commande.is_configInternaute || commande.is_petite_source_internaute ? true : false"
                      />
                      <v-divider v-if="commande.statut != 'devis' && commande.adv_contact != false" />
                      <Contact
                        v-if="commande.statut != 'devis' && commande.adv_contact != false"
                        :contact="commande.adv_contact"
                        :is-petite-source="commande.is_petite_source"
                        :client="{}"
                        :statut="commande.statut"
                        :fonction="$t('administration-ventes')"
                        tel="telephone"
                        :prise-rdv-possible="false"
                      />
                      <v-divider v-if="commande.statut != 'devis' && commande.adv_contact != false" />
                      <Contact
                        v-if="commande.statut != 'devis' && commande.install_contact != false"
                        :contact="commande.install_contact"
                        :is-petite-source="commande.is_petite_source"
                        :client="{}"
                        :statut="commande.statut"
                        :fonction="$t('planification-chantiers')"
                        tel="telephone"
                        :prise-rdv-possible="false"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="elevation contacts mt-14 contacter-conseiller position-fixed fixed-bottom"
                    >
                      <v-dialog
                        v-model="dialog"
                        scrollable
                        transition="dialog-bottom-transition"
                        fullscreen
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <div class="text-center px-5 py-3">
                            <p
                              v-if="!commande.signature_en_cours || commande.statut == 'facture'"
                              class="muted--text mb-2 mt-0"
                            >
                              {{ $t('nous-sommes-la') }}
                            </p>
                            <v-btn
                              v-if="!commande.signature_en_cours.lien || commande.statut == 'facture'"
                              class="w-100 background-primary primary-hover"
                              :class="$vuetify.breakpoint.xs ? (user.is_petite_source ? 'background-ps-primary primary-ps-hover d-block' : 'background-primary primary-hover d-block') : (user.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover')"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ commande.statut != 'devis' ? $t('contactez-nous') : $t('contacter-conseiller') }}
                            </v-btn>
                            <div v-else>
                              <v-btn
                                target="_blank"
                                dark
                                :href="commande.signature_en_cours.lien.yousign_url"
                                class="background-primary primary-hover"
                                :class="$vuetify.breakpoint.xs ? (user.is_petite_source ? 'background-ps-primary primary-ps-hover w-100' : 'background-primary primary-hover w-100') : (user.is_petite_source ? 'background-ps-primary primary-ps-hover' : 'background-primary primary-hover')"
                              >
                                {{ $t('signature-electronique') }}
                              </v-btn>
                              <p class="muted--text mt-2 mb-0">
                                {{ $t('une-question') }}
                                <span
                                  v-bind="attrs"
                                  class="text-decoration-underline"
                                  v-on="on"
                                >
                                  {{ $t('contacter-conseiller') }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </template>
                        <v-card class="contenu-piscinelle">
                          <v-card-title
                            class="dialog-card-color pa-3 white--text flex-nowrap align-start"
                          >
                            {{ $t('contactez-nous') }}
                            <v-spacer />
                            <v-btn
                              icon
                              dark
                              @click="dialog = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>
                          <Contact
                            v-if="commande.statut == 'facture'"
                            :contact="commande.sav_infos"
                            :show-sav="true"
                            :is-kit="commande.is_kit"
                            :client="{}"
                            :statut="commande.statut"
                            tel="telephone"
                            :prise-rdv-possible="false"
                          />
                          <v-divider v-if="commande.statut == 'facture'" />
                          <Contact
                            :contact="commande.vendeur_infos"
                            :client="user"
                            :lien-signature="commande.signature_en_cours.lien ? commande.signature_en_cours.lien.yousign_url : ''"
                            :fonction="$t('votre-conseiller-piscinelle')"
                            tel="tel_portable"
                            :show-btn-demande-de-rappel="commande.statut != 'facture' && !commande.vendeur_infos.custom_field_array.desactiver_demande_de_rappel ? true : false"
                            :statut="commande.statut"
                            :show-btn-signature="commande.statut != 'facture' && commande.signature_en_cours && commande.date_validite && commande.date_validite >= today() ? true : false"
                            :config-internaute="commande.is_configInternaute || commande.is_petite_source_internaute"
                            :prise-rdv-possible="commande.is_configInternaute || commande.is_petite_source_internaute ? true : false"
                            :is-petite-source="commande.is_petite_source"
                          />
                          <v-divider v-if="commande.statut != 'devis' && commande.adv_contact != false" />
                          <Contact
                            v-if="commande.statut != 'devis' && commande.adv_contact != false"
                            :contact="commande.adv_contact"
                            :client="{}"
                            :statut="commande.statut"
                            :fonction="$t('administration-ventes')"
                            tel="telephone"
                            :prise-rdv-possible="false"
                          />
                          <v-divider v-if="commande.statut != 'devis' && commande.adv_contact != false" />
                          <Contact
                            v-if="commande.statut != 'devis' && commande.install_contact != false"
                            :contact="commande.install_contact"
                            :client="{}"
                            :statut="commande.statut"
                            :fonction="$t('planification-chantiers')"
                            tel="telephone"
                            :prise-rdv-possible="false"
                          />
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Chatbot v-if="commande.statut == 'facture'" />
  </div>
</template>

<script>
import CommandeCard from '@/components/commande/Card'
import Contact from '@/components/contact/Contact'
import ChoisirPiscinelle from '@/components/ChoisirPiscinelle'
import Header from '@/components/Header'
import InfosListe from '@/components/InfosListe'
import Drawer from '@/components/Drawer'
import Facilites from '@/components/Facilites'
import Chatbot from '@/components/Chatbot.vue'

import { mapActions, mapGetters } from 'vuex'

import { DateTime } from 'luxon'

import { bus } from '@/main'

export default {
  name: 'Commande',
  components: {
    Header,
    Contact,
    ChoisirPiscinelle,
    InfosListe,
    Drawer,
    CommandeCard,
    Facilites,
    Chatbot
  },
  metaInfo () {
    return {
      title: this.metaTitle
    }
  },
  data () {
    return {
      items: [
        {
          mens: 180
        },
        {
          mens: 120
        },
        {
          mens: 90
        }
      ],
      selectedScrollSpyItem: null,
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL,
      petiteSourceUrl: process.env.VUE_APP_PETITE_SOURCE_URL,
      pageLoading: true,
      dialog: false,
      show_global: true,
      infos_chantier: false,
      loading: false,
      interne: false,
      contenu_prerequis_technique: false,
      dialogSupprimerConfig: false,
      metaTitle: '',
      tutoKey: window.btoa(DateTime.now().plus({ months: 1 }).toFormat('yyyy-MM-dd'))
    }
  },
  computed: {
    ...mapGetters([
      'getCommandeById',
      'user',
      'success',
      'avisLaisse',
      'suppression',
      'demande_rappel',
      'envoi_mail_proche'
    ]),
    commande () {
      return this.getCommandeById(this.$route.params.id)
    },
    reseauxSociaux () {
      return [
        {
          title: 'Facebook',
          icon: 'mdi-facebook',
          color: '#3b5998',
          share_url: `https://www.facebook.com/sharer/sharer.php?u=${process.env.VUE_APP_PISCINELLE_URL}tarifs/share?id=${this.commande.n}`
        },
        {
          title: 'Messenger',
          icon: 'mdi-facebook-messenger',
          color: '#00B2FF',
          share_url: `http://www.facebook.com/dialog/send?app_id=${this.$app_id}&link=${process.env.VUE_APP_PISCINELLE_URL}tarifs/share?id=${this.commande.n}&redirect_uri=${process.env.VUE_APP_PISCINELLE_URL}tarifs/share?id=${this.commande.n}`
          // share_url: `fb-messenger://share/?link=${process.env.VUE_APP_PISCINELLE_URL}tarifs/share?id=${this.commande.n}&app_id=${this.$app_id}`
        },
        {
          title: 'Whatsapp',
          icon: 'mdi-whatsapp',
          color: '#25D366',
          share_url: `https://api.whatsapp.com/send?text=${process.env.VUE_APP_PISCINELLE_URL}tarifs/share?id=${this.commande.n}`,
          action: 'share/whatsapp/share'
        }
      ]
    },
    scrollSpyItems () {
      const items = []

      if ((this.commande.statut !== 'devis')) {
        items.push({ titre: this.$t('statut_commande'), id: 'statut_commande' })
      } else if (this.commande.type_cmd !== '8') {
        if (this.commande.type_cmd === '1') { items.push({ titre: this.$t('etapes_projet'), id: 'etapes_projet' }) }
        items.push({ titre: this.$t('choisir-piscinelle.title'), id: 'choisir_piscinelle' })
      }

      if (this.commande.type_cmd === '6') {
        items.push({ titre: this.$t('partie-installation'), id: 'partie_installation' })
        if (this.commande.offre_liee) { items.push({ titre: this.$t('partie-materiel'), id: 'partie_materiel' }) }
      } else {
        items.push({ titre: (this.commande.type_cmd === '8' ? this.$t('partie-ps') : this.$t('partie-materiel')), id: 'partie_materiel' })
        if (this.commande.offre_liee) { items.push({ titre: this.$t('partie-installation'), id: 'partie_installation' }) }
      }
      if (this.commande.offre_liee) {
        items.push({ titre: this.$t('cout_global'), id: 'cout_global' })
      }

      if (!this.commande.is_configInternaute) {
        if (this.commande.infos_zone_a_couvrir) {
          items.push({ titre: this.$t('infos_zone_a_couvrir'), id: 'infos_zone_a_couvrir' })
        }
        if (this.commande.infos_bassin) {
          items.push({ titre: this.$t('infos_bassin'), id: 'infos_bassin' })
        }

        if (this.commande.is_petite_source && this.contenu_prerequis_technique) {
          items.push({ titre: this.$t('prerequis_techniques'), id: 'prerequis_techniques' })
        }

        if (this.commande.infos_rd) {
          items.push({ titre: this.$t('infos_rolling_deck'), id: 'infos_rolling_deck' })
        }
        if (this.commande.infos_installation) {
          items.push({ titre: this.$t('infos_installation'), id: 'infos_installation' })
        }

        items.push({ titre: this.$t('mes-adresses'), id: 'adresses' })

        if (this.schemas.length) {
          items.push({ titre: this.$t('schemas_implantation'), id: 'schemas_implantation' })
        }

        if (this.commande.infos_chantier && this.commande.is_eligible_infos_chantier_ec) {
          items.push({ titre: this.$t('infos_chantier'), id: 'infos_chantier' })
        }
      }

      if (this.commande.statut !== 'devis' && this.commande.type_cmd === '1') {
        items.push({ titre: this.$t('ressources_utiles'), id: 'ressources_utiles' })
      }
      return items
    },
    schemas () {
      return [this.commande.schema_auto, this.commande.schema_eps, this.commande.schema_rd].filter(Boolean)
    },
    ressourcesUtilesItems () {
      return [
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'packing_list',
          to: { name: 'PackingList' }
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'schemas_implantation_personnalises',
          href: `${process.env.VUE_APP_EXTRANET_URL}divers/schemas-implantation-piscine?cm_id=${this.commande.n}&ct_id=${this.commande.client}`
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'notice_equipements',
          href: 'https://drive.google.com/drive/folders/1nzu7llLmpM9Cr6QuUmIWusROjISm5lEl'
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'notice_entretien',
          href: 'https://drive.google.com/file/d/1EsOQ1b1GvPbylFghcPygmuDeRSxyHiBn/view?usp=sharing'
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'guide_installation',
          href: `${process.env.VUE_APP_PISCINELLE_URL}utils/guide-installation?piscine=${this.commande.n}`
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'notice_montage_alu',
          href: 'https://drive.google.com/file/d/1voEKePDfHInVUi0egAn1TEy70NRYjPh9/view?usp=drive_web',
          hide: !this.commande.is_alu
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'notice_montage_bois',
          href: 'https://drive.google.com/file/d/0B3tizmiQ_NgFMkxPaGtOVjRIZXc/view?usp=sharing&resourcekey=0-EhhdlOGKXo5qIX9O0a_-9g',
          hide: !this.commande.is_bois
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'notice_rd',
          href: 'https://drive.google.com/file/d/0B3tizmiQ_NgFbDM3dXYtdkxlYXc/view?usp=sharing&resourcekey=0-7Wjja5jkmawYOq5mqIhdGw',
          hide: !this.commande.infos_rd
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'tutos',
          href: `${process.env.VUE_APP_PISCINELLE_URL}guide-installation-aluminium?key=${this.tutoKey}`,
          important: 1,
          hide: this.commande.is_bois
        },
        {
          icon: 'mdi-playlist-check ',
          keyTrad: 'tutos',
          href: `${process.env.VUE_APP_PISCINELLE_URL}guide-installation-bois?key=${this.tutoKey}`,
          important: 1,
          hide: this.commande.is_alu
        }
      ]
    }
  },
  watch: {
    $route: 'loadInfos'
  },
  created () {
    this.loadInfos()
    bus.$on('close_mobile_dialog', (data) => {
      this.dialog = data
    })
  },
  updated () {
    window.addEventListener('scroll', this.changeRubriqueAuScroll)
    window.addEventListener('scroll', this.hideScrollSpy)
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    bus.$off('close_mobile_dialog')
    window.removeEventListener('scroll', this.changeRubriqueAuScroll)
    window.removeEventListener('scroll', this.hideScrollSpy)
  },
  methods: {
    ...mapActions(['getCommande', 'getContenu']),
    changeRubriqueAuScroll () {
      const scrollElements = document.querySelectorAll('.scrollme')
      scrollElements.forEach((scrollElement) => {
        const scrollElementTop = scrollElement.getBoundingClientRect().top
        const scrollElementBottom = scrollElement.getBoundingClientRect().bottom
        if (scrollElementTop <= 100 && scrollElementBottom >= 100) {
          const scrollSpyItem = this.scrollSpyItems.find(element => element.id === scrollElement.getAttribute('id'))
          this.selectedScrollSpyItem = scrollSpyItem
        }
      })
    },
    goToSection () {
      var element = document.getElementById(this.selectedScrollSpyItem.id)
      var offset = 50
      var elementPosition = element.getBoundingClientRect().top
      var offsetPosition = elementPosition + window.pageYOffset - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    },
    coutGlobal (offre, offreLiee) {
      return (parseInt(offre) + parseInt(offreLiee))
    },
    hideScrollSpy () {
      const scrollspy = document.querySelector('.scrollspy-row')
      const scrollElementBottom = document.querySelector('.first-col').getBoundingClientRect().top + 150 // On applique 150px de bonus pour compnser les padding et margin
      if (window.scrollY < scrollElementBottom) {
        scrollspy.setAttribute('style', 'display:none !important')
      } else {
        scrollspy.setAttribute('style', 'display:block !important')
      }
    },
    urlGenererPdf () {
      return (`${process.env.VUE_APP_EXTRANET_URL}divers/offre-pdf?cm_id=${this.commande.n}&ct_id=${this.commande.client}`)
    },
    modifierConfigUrl () {
      return this.commande.is_petite_source ? (`${process.env.VUE_APP_PETITE_SOURCE_URL}commande/configurateur?cmd=${this.commande.n}`) : (`${process.env.VUE_APP_PISCINELLE_URL}tarifs?id=${this.commande.n}`)
    },
    supprimerConfig () {
      this.loading = true
      this.$store.dispatch('suppression', this.commande.n).then(() => {
        this.dialogSupprimerConfig = false
        this.loading = false
      })
    },
    loadInfos () {
      this.metaTitle = ''
      if (this.$route.query.token) this.interne = true

      this.getCommande(this.$route.params.id).then(res => {
        this.metaTitle = res.n + ' ' + res.nom_fact
        if (res.is_petite_source) {
          this.getContenu(2595).then(contenu => {
            this.contenu_prerequis_technique = contenu.params.lang[this.$i18n.locale].contenu
          })
        }
      })

      this.pageLoading = false
    }
  }
}
</script>
